import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="leviia"
    name="Leviia"
    shortDescription="Store your file, database, saas and storage backup to Leviia."
    website={"https://www.leviia.com/"}
    links={[{text: 'Documentation', url: 'https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/leviia/ukwCunRiU7116vctk4Rhas'}]}
    category="Storage"
    logo="leviia-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Leviia')}
    metaDescription={meta.description.replace('##provider##', 'Leviia')}
  >
    <IntegrationStorageContent
      keyRef="leviia"
      service="Leviia"
      provider="Leviia"
    />
  </IntegrationTemplate>
)

export default ContentPage
